import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";

import BlockText from "../components/content/block-text";
import PageLayout from "../components/layout/page-layout";
import { PageContext, pages } from "../context/page-context";

import style from "../styles/page-styles/privacy-information.module.scss";

function PrivacyPage({ data }) {
  const pageContext = useContext(PageContext);
  useEffect(() => {
    pageContext.setCurrentPage(pages.privacy);
  }, [pageContext.currentPage]);
  return (
    <PageLayout>
      <div className={`container ${style.pageContent}`}>
        <BlockText blocks={data.sanityPrivacyPage._rawPrivacyInfo} />
      </div>
    </PageLayout>
  );
}
export default PrivacyPage;

export const privacyPageQuery = graphql`
  query privacyPage {
    sanityPrivacyPage {
      _rawPrivacyInfo
      _id
    }
  }
`;
